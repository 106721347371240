<template lang="pug">
.receipt-list
  .d-flex.justify-between.align-center
    .text-gray-900.text-2xl Receipts 
    div
      v-btn(
        color="primary"
        v-if="$can('create', 'receipts')"
        @click="isReceiptCreateEditOpen = true"
        depressed
      ) + New Receipt

  .d-flex.mt-16.gap-8
    v-text-field(
      class="w-full lg:w-1/2 xl:w-1/3 mb-4"
      v-model="search"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      outlined
      dense
      clearable
    )
    StateList(
      class="w-full lg:w-1/2 xl:w-1/3 mb-4"
      v-model="stateId"
      multiple
    )
    .flex-shrink-1
      v-menu( offset-x bottom left )
        template( v-slot:activator="{ on, attrs }" )
          v-btn(
            depressed
            small
            style="height: 40px"
            v-bind="attrs"
            v-on="on"
          )
            v-icon mdi-download
        v-list
          v-list-item( link @click="exportReceipts" )
            v-list-item-title Export All ({{ !isPending && pagination ? pagination.total : 0 }})

  v-data-table.border.shadow(
    must-sort
    :items="receipts"
    :loading="isPending"
    :headers="headers"
    :footer-props="{ itemsPerPageOptions:[25,50,100,-1] }"
    :server-items-length="!isPending && pagination ? pagination.total : 0"
    :options.sync="options"
    no-data-text="No receipts found"
  )
    template( v-slot:body.append )
      tr.bg-gray-100( v-if="!isPending" )
        td  
          span Sum
        td( :colspan="headers.length - 3" )
        td.text-end( v-if="sum" )
          span.font-bold {{ sum.formatted }}
        td
    template(
      v-slot:item.receiptDate="{ item: receipt }"
    )
      | {{ $day(receipt.receiptDate).format(formats.DATE) }}
    template(
      v-slot:item.phone="{ item: receipt }"
    )
      | {{ receipt.formattedPhone }}
    template(
      v-slot:item.amount="{ item }"
    )
      h6 {{ item.formattedMoney }}
    template(
      v-slot:item.stateId="{ item }"
    )
      h6 {{ item.state.short }}
    template(
      v-slot:item.actions="{ item: receipt }"
    )
      v-menu( 
        left 
        offset-y 
        v-if="$can('patch', 'receipts')"
      )
        template( v-slot:activator="{ on, attrs }" )
          v-btn(
            color="gray"
            v-bind="attrs"
            v-on="on"
            depressed
            small
            fab
          )
            v-icon mdi-dots-vertical
        v-list.divide-y
          v-list-item( 
            class="hover:bg-gray-200 cursor-pointer"
            @click="editingReceipt = receipt; isReceiptCreateEditOpen = true"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-square-edit-outline
            v-list-item-content
              v-list-item-title.cursor-pointer Edit

  ReceiptCreateEditDialog(
    :isOpen="isReceiptCreateEditOpen"
    @close="closeAndRefresh"
    :editingReceipt="editingReceipt"
    v-if="isReceiptCreateEditOpen"
  )
</template>

<script>
import { formats } from '@/helpers/data'
import { ref, computed } from '@vue/composition-api'
import ReceiptCreateEditDialog from '@/views/receipts/Receipt.CreateEdit.Dialog'
import useDataTableFind from '@/helpers/useDataTableFind'
import dataTableHeaders from '@/helpers/dataTableHeaders'
import * as XLSX from 'xlsx';
import StateList from '@/components/StateList.vue'
import { isOrganization } from '@/helpers/identityHelper'

export default {
  name: 'ReceiptList',

  components: {
    StateList,
    ReceiptCreateEditDialog
  },

  methods: {},

  setup(props, { root: { $day, $store }}) {
    const { email, organizationId } = $store.getters['auth/user'];
    const isReceiptCreateEditOpen = ref(false)
    const editingReceipt = ref(null)

    const closeAndRefresh = () => {
      findReceipts()
      isReceiptCreateEditOpen.value = false;
      editingReceipt.value = null;
    };

    const { headers, filterableColumns } = dataTableHeaders(
      [
        {
          text: 'Date',
          value: 'receiptDate'
        },
        {
          text: 'FirstName',
          value: 'firstName',
          filterable: true
        },
        {
          text: 'Lastname',
          value: 'lastName',
          filterable: true
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: false
        },    
        {
          text: 'State',
          value: 'stateId',
          sortable: true
        },     
        {
          text: 'Amount',
          value: 'amount',
          align: 'end',
        },
      ],
      [
        'orgType.name'
      ],
      true
    )

    if (isOrganization) {
      headers.splice(
        1, 
        0, 
        {
          text: 'Org Type',
          class: 'bg-gray-100 uppercase',
          value: 'orgType.name',
          sortable: false
        }
      )
    }

    const exportReceipts = async () => {
      const query = latestParams.value.query;
      query.$joinEager = '[state, organization]';

      const params = {
        delivery: 'download',
        emails: email,
        organizationId,
        services: [['receipts', query]]
      }
      const { 
        exports: [{ data: expenseColumns, wsWidths }] 
      } = await $store.dispatch('exporter/create', params)
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(expenseColumns)
      
      ws['!cols'] = wsWidths;

      XLSX.utils.book_append_sheet(wb, ws, 'Receipts')

      const timestamp = $day().unix()
      XLSX.writeFile(wb, `Receipts_Export_${ timestamp }.xlsx`)
    }

    const stateId = ref([]);
    const $joinEager = isOrganization
      ? '[state, orgType]' : '[state]';
    const filter = computed(() => {
      const filter = {
        $joinEager
      }

      if (stateId.value.length > 0)
        filter.stateId = { $in: stateId.value }

      return filter
    })
    const { 
      items: receipts, 
      isPending, 
      pagination, 
      findItems: findReceipts, 
      search, 
      options,
      sum,
      latestParams
    } = useDataTableFind(
      'receipts',
      filterableColumns,
      filter,
      { sortBy: ['receiptDate'] }
    )

    return {
      headers,
      isReceiptCreateEditOpen,
      filter,

      pagination,
      receipts,
      isPending,
      options,
      formats,
      editingReceipt,
      search,
      stateId,
      sum,

      findReceipts,
      closeAndRefresh,
      exportReceipts
    }
  }
}
</script>