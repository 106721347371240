var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"receipt-list"},[_c('div',{staticClass:"d-flex justify-between align-center"},[_c('div',{staticClass:"text-gray-900 text-2xl"},[_vm._v("Receipts ")]),_c('div',[(_vm.$can('create', 'receipts'))?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.isReceiptCreateEditOpen = true}}},[_vm._v("+ New Receipt")]):_vm._e()],1)]),_c('div',{staticClass:"d-flex mt-16 gap-8"},[_c('v-text-field',{staticClass:"w-full lg:w-1/2 xl:w-1/3 mb-4",attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify","outlined":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('StateList',{staticClass:"w-full lg:w-1/2 xl:w-1/3 mb-4",attrs:{"multiple":""},model:{value:(_vm.stateId),callback:function ($$v) {_vm.stateId=$$v},expression:"stateId"}}),_c('div',{staticClass:"flex-shrink-1"},[_c('v-menu',{attrs:{"offset-x":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"40px"},attrs:{"depressed":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.exportReceipts}},[_c('v-list-item-title',[_vm._v("Export All ("+_vm._s(!_vm.isPending && _vm.pagination ? _vm.pagination.total : 0)+")")])],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"border shadow",attrs:{"must-sort":"","items":_vm.receipts,"loading":_vm.isPending,"headers":_vm.headers,"footer-props":{ itemsPerPageOptions:[25,50,100,-1] },"server-items-length":!_vm.isPending && _vm.pagination ? _vm.pagination.total : 0,"options":_vm.options,"no-data-text":"No receipts found"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.append",fn:function(){return [(!_vm.isPending)?_c('tr',{staticClass:"bg-gray-100"},[_c('td',[_c('span',[_vm._v("Sum")])]),_c('td',{attrs:{"colspan":_vm.headers.length - 3}}),(_vm.sum)?_c('td',{staticClass:"text-end"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.sum.formatted))])]):_vm._e(),_c('td')]):_vm._e()]},proxy:true},{key:"item.receiptDate",fn:function(ref){
var receipt = ref.item;
return [_vm._v(_vm._s(_vm.$day(receipt.receiptDate).format(_vm.formats.DATE)))]}},{key:"item.phone",fn:function(ref){
var receipt = ref.item;
return [_vm._v(_vm._s(receipt.formattedPhone))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('h6',[_vm._v(_vm._s(item.formattedMoney))])]}},{key:"item.stateId",fn:function(ref){
var item = ref.item;
return [_c('h6',[_vm._v(_vm._s(item.state.short))])]}},{key:"item.actions",fn:function(ref){
var receipt = ref.item;
return [(_vm.$can('patch', 'receipts'))?_c('v-menu',{attrs:{"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"gray","depressed":"","small":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"divide-y"},[_c('v-list-item',{staticClass:"hover:bg-gray-200 cursor-pointer",on:{"click":function($event){_vm.editingReceipt = receipt; _vm.isReceiptCreateEditOpen = true}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-square-edit-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"cursor-pointer"},[_vm._v("Edit")])],1)],1)],1)],1):_vm._e()]}}])}),(_vm.isReceiptCreateEditOpen)?_c('ReceiptCreateEditDialog',{attrs:{"isOpen":_vm.isReceiptCreateEditOpen,"editingReceipt":_vm.editingReceipt},on:{"close":_vm.closeAndRefresh}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }