<template lang="pug">
.receipt-create-edit-dialog
  CreateStepperEditDialog(
    title="Add Receipt"
    :item="receipt"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveReceipt"
    :saveAction="'Save'"
  )
    template(
      #stepper="{ clone, rules }"
    )
      v-stepper( v-model="step" elevation="0" )
        v-stepper-header
          v-stepper-step(
            :complete="step > 1"
            step="1"
            :rules="[() => validIdentity(clone) || step === 1]"
            :color="stepColor(1, validIdentity(clone))"
            :error-icon="'mdi-alert-circle'"
          )
            div( @click="step = 1" class="cursor-pointer hover:underline" )
              | Identity

          v-divider

          v-stepper-step(
            :complete="step > 2"
            step="2"
            :error-icon="'mdi-alert-circle'"
          )
            div( @click="step = 2" class="cursor-pointer hover:underline" )
              | Receipt

        v-stepper-items
          v-stepper-content( step="1" )
            .d-flex.justify-content-between.gap-8
              v-text-field(
                outlined
                dense
                label="First Name"
                v-model="clone.firstName"
                :rules="[rules.required]"
              )

              v-text-field(
                outlined
                dense
                label="Last Name"
                v-model="clone.lastName"
                :rules="[rules.required]"
              )

            .d-flex.justify-content-between.gap-8
              v-text-field(
                class="w-1/2 min-w-0"
                outlined
                dense
                label="Phone"
                v-model="clone.phone"
                :rules="[rules.required]"
              )

              v-text-field(
                class="w-1/2 min-w-0"
                outlined
                dense
                label="Occupation"
                v-model="clone.occupation"
              )

            v-text-field(
              outlined
              dense
              label="Employer"
              v-model="clone.employer"
              :rules="[rules.required]"
            )

            AddressForm(
              :rules="rules"
              :clone="clone"
            )

          v-stepper-content( step="2" )
            OrgTypeSelect.mb-6(
              v-if="isOrganization && isOrgAdmin"
              v-model="clone.organizationTypeIds"
              single-select
              :is-picker="isOrgAdmin"
            )

            v-select(
              v-if="isOrganization && !isOrgAdmin"
              class="w-full min-w-0"
              :items="orgTypes"
              v-model="clone.organizationTypeId"
              outlined
              dense
              label="Org Types"
              item-value="id"
              item-text="name"
              :isLoading="isOrgTypeFindPending"
              no-data-text="No Org types found"
              :rules="[rules.required]"
            )

            v-divider.mb-6(
              v-if="isOrganization"
            )

            DatePickerDialog(
              inputClass="w-full min-w-0"
              label="Receipt Date"
              v-model="clone.receiptDate"
            )

            .d-flex.justify-content-between.gap-8
              div( class="w-full md:w-1/2" )
                AmountFormItem(
                  v-model="clone.amount"
                  :rules="rules"
                )

              v-select(
                class="w-full md:w-1/2 min-w-0"
                v-model="clone.depositType"
                outlined
                dense
                label="Deposit Type"
                :items="depositTypes"
              )

    template( #action="{ clone, valid }" )
      .d-flex.justify-space-between.w-full
        v-btn(
          depressed
          @click="$emit('close')"
        ) Cancel
        .d-flex
          div.mr-2
            v-btn(
              :disabled="step === 1"
              depressed
              color="secondary"
              type="button"
              @click="step--"
            ) Back
          div
            v-btn(
              v-if="step !== 2"
              :disabled="step === 2"
              depressed
              color="primary"
              type="button"
              @click="step++"
            ) Next
          div
            v-btn(
              v-if="step === 2"
              :disabled="!(valid && clone.amount !== '')"
              depressed
              color="primary"
              type="submit"
            ) {{ editingReceipt ? 'Save' : 'Add' }}
</template>

<script>
import { ref } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { isOrganization } from '@/helpers/identityHelper'
import AddressForm from '@/components/Address.Form'
import AmountFormItem from '@/components/Amount.FormItem'
import DatePickerDialog from '@/components/DatePicker.Dialog'
import CreateStepperEditDialog from '@/components/Dialogs/CreateEdit.Stepper.Dialog'
import OrgTypeSelect from '@/views/organization-types/OrgType.Select.Dialog'
import permissions from '@/helpers/uiContext';

export default {
  name: 'ReceiptCreateEditDialog',

  components: {
    AddressForm,
    DatePickerDialog,
    CreateStepperEditDialog,
    AmountFormItem,
    OrgTypeSelect
  },

  props: {
    title: {
      type: String,
      default: 'Add Receipt'
    },
    editingReceipt: {
      type: Object,
      required: false,
      default: null
    },
    isOpen: Boolean
  },

  setup(props, { root: { $FeathersVuex, $snackSuccess, $snackError }, emit }) {
    const step = ref(1);
    const { Receipt, OrganizationType } = $FeathersVuex.api
    let receipt = props.editingReceipt

    if (props.editingReceipt) {
      const { organizationTypeId } = props.editingReceipt
      receipt = new Receipt(Object.assign(props.editingReceipt, { organizationTypeIds: [organizationTypeId] }))
    } else {
      receipt = new Receipt()
    }

    const { isOrgAdmin } = permissions()

    const depositTypes = ref([
      { text: 'Check', value: 'Check' },
      { text: 'Cash', value: 'Cash' },
      { text: 'Wire', value: 'Wire' },
      { text: 'In-Kind', value: 'In-Kind' },
    ])

    const validIdentity = (clone) => {
      return !!clone.firstName && !!clone.lastName && !!clone.street
        && !!clone.zipCode && !!clone.city && !!clone.occupation;
    }

    const saveReceipt = async ({ clone, save }) => {
      try {
        if (clone.organizationTypeIds) {
          clone.organizationTypeId = clone.organizationTypeIds[0]
          delete clone.organizationTypeIds
        }

        await save()
        $snackSuccess('Receipt ' + (props.editingReceipt ? 'Saved' : 'Created'))
        emit('close')
      } catch (e) {
        console.log(e)
        $snackError('Error adding the Receipt', e.message)
      }
    }

    let orgTypes = ref([])
    let isOrgTypeFindPending = ref(false)

    if (isOrganization) {
      const orgTypeData = useFind({
        model: OrganizationType,
        params: {
          query: {
            $sort: { name: 1 }
          }
        }
      })

      orgTypes = orgTypeData.items;
      isOrgTypeFindPending = orgTypeData.isPending;
    }

    const stepColor = (selectStep, isValid) => {
      const currentStep = step.value;

      if (currentStep > selectStep)
        return isValid ? 'success' : 'error'
      else
        return 'primary'
    }

    return {
      step,
      receipt,
      isOrganization,
      orgTypes,
      isOrgTypeFindPending,
      depositTypes,
      isOrgAdmin,

      stepColor,
      saveReceipt,
      validIdentity
    }
  }
}
</script>